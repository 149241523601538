import styled from 'styled-components'
import landingPageBg from '../frontend/assets/images/landing/landing-bg.png'
import landingPageBg1024 from '../frontend/assets/images/landing/landing-bg-1024.png'
import landingPageBg768 from '../frontend/assets/images/landing/landing-bg-768.png'
import device from '../frontend/utils/device-size'

export default styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(${landingPageBg});

  .landing-page-container {
    width: 100%;
    height: 100%;
    padding: 56px 72px;

    .logo-wrapper {
      width: 224px;
      height: 72.2px;
      align-self: baseline;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .landing-content-wrapper {
      width: 100%;
      height: 100%;
      .landing-content-block {
        text-align: center;
        color: #fff;
        .quote-wrapper {
          font-family: 'Prompt';
          font-size: 48px;
          margin-bottom: 16px;
        }
        .sub-quote-wrapper {
          font-family: 'Prompt-Thin';
          font-weight: 100;
          font-size: 24px;
          margin-bottom: 114px;
        }
        .button-wrapper {
          width: 240px;
          height: 40px;
          button {
            width: 100%;
            border-radius: 0;
          }
        }
      }
    }

    .powered-by-wrapper {
      height: 38px;
      font-family: 'Prompt';
      font-size: 16px;
      color: #fff;
      .powered-by-logo-wrapper {
        margin-left: 16px;
        width: 154px;
        height: 38px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    background-image: url(${landingPageBg1024});
    .landing-page-container {
      padding: 24px;
      padding-bottom: 105px;
      .logo-wrapper {
        width: 160px;
        height: 51.6px;
        align-self: baseline;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .landing-content-wrapper {
        width: 100%;
        height: 100%;
        .landing-content-block {
          .quote-wrapper {
            font-family: 'Prompt';
            font-size: 36px;
            margin-bottom: 11px;
          }
          .sub-quote-wrapper {
            font-family: 'Prompt-Thin';
            font-weight: 100;
            font-size: 24px;
            margin-bottom: 112px;
          }
        }
      }
      .powered-by-wrapper {
        height: 35px;
        font-family: 'Prompt';
        font-size: 16px;
        color: #fff;
        .powered-by-logo-wrapper {
          margin-left: 16px;
          width: 140px;
          height: 35px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${device.maxSizeS}) {
    background-image: url(${landingPageBg768});
    .landing-page-container {
      padding: 24px;
      padding-bottom: 120px;
      .logo-wrapper {
        width: 124px;
        height: 40.3px;
        align-self: baseline;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .landing-content-wrapper {
        width: 100%;
        height: 100%;
        .landing-content-block {
          .quote-wrapper {
            font-family: 'Prompt';
            font-size: 36px;
            margin-bottom: 16px;
          }
          .sub-quote-wrapper {
            font-family: 'Prompt-Thin';
            font-weight: 100;
            font-size: 24px;
            margin-bottom: 155px;
          }
        }
      }
      .powered-by-wrapper {
        height: 35px;
        font-family: 'Prompt';
        font-size: 16px;
        color: #fff;
        .powered-by-logo-wrapper {
          margin-left: 16px;
          width: 140px;
          height: 35px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
`
