import React from 'react'
import Router from 'next/router'
import Head from 'next/head'
import LandingPageStyled from './styled'
import Button from '../frontend/components/Button/dynamic'

import FraserLogoSrc from '../frontend/assets/images/header/logo-fpt.svg'
import SertisLogoSrc from '../frontend/assets/images/landing/sertis-logo.png'

export default class LandingPage extends React.Component {
  handleGoToSiteClick = () => {
    Router.push(`/api/auth/login`)
  }

  renderLeftLogo() {
    return (
      <div className="logo-wrapper">
        <img src={FraserLogoSrc} />
      </div>
    )
  }

  renderMidContent() {
    return (
      <div className="landing-content-wrapper flex justify-center align-items-center">
        <div className="landing-content-block flex flex-column align-items-center">
          <div className="quote-wrapper">Dashway</div>
          <div className="sub-quote-wrapper">Monitor Every Activity Through One Window</div>
          <div className="button-wrapper">
            <Button id="btn-landing-page" onClick={this.handleGoToSiteClick} text="Go to Site" />
          </div>
        </div>
      </div>
    )
  }

  renderPoweredBy() {
    return (
      <div className="powered-by-wrapper flex align-items-center">
        Powered by
        <div className="powered-by-logo-wrapper">
          <img src={SertisLogoSrc} />
        </div>
      </div>
    )
  }

  renderContent() {
    return (
      <div className="landing-page-container flex flex-column align-items-center">
        {this.renderLeftLogo()}
        {this.renderMidContent()}
        {this.renderPoweredBy()}
      </div>
    )
  }

  render() {
    return (
      <LandingPageStyled>
        <Head>
          <title>Frasers Property</title>
        </Head>
        {this.renderContent()}
      </LandingPageStyled>
    )
  }
}
